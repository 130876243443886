import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { Helmet } from 'react-helmet';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import Tooltip from 'rc-tooltip';

import URLFiltersHoc, { Props } from 'components/URLFiltersHoc';
import Breadcrumbs from 'components/Breadcrumbs';
import FilterList from 'components/FilterList';
import TableList from 'components/TableList';
import SweetAlert from '@sweetalert/with-react';

import Alert from 'helpers/alert';
import API from 'api';

import classes from './AnnualPlans.module.scss';

interface IOwnProps {
  agencyId: number;
  checkRoles: (role: string) => boolean;
}

interface IState {
  filterItems: any[],
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
  dataListActive: number;
}

class AnnualPlans extends PureComponent<Props & IOwnProps, IState> {
  state: IState = {
    filterItems: [],
    dataList: [],
    dataListTotal: 0,
    dataListActive: 0,
    isLoading: false,
  };

  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;

  componentDidMount () {
    this.mounted = true;
    this.refreshFilterItems();
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
    if (prevProps.agencyId !== this.props.agencyId) {
      this.refreshList();
      this.refreshFilterItems();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
    };


    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });
      try {
        dataListRequest.filter.items = dataListRequest.filter.items
          .filter((item) => !item.includes('AGENCY'));

        dataListRequest.filter.items.push(`AGENCY_${this.props.agencyId}`);

        dataListRequest.filter.items = [...new Set(dataListRequest.filter.items)];

        const dataList = await API.annualPlans.list(dataListRequest);

        if (!this.mounted) {
          return;
        }

        this.setState({
          dataList: dataList.items,
          dataListTotal: dataList.total,
          dataListActive: dataList.active,
          isLoading: false,
        });
      } catch (e) {
        this.setState({ isLoading: false });
      }
    }, this.refreshListTimerDelay);
  }

  async refreshFilterItems () {
    const filterItems = await API.annualPlans.getFilterItems(this.props.agencyId);

    if (!this.mounted) {
      return;
    }

    this.setState({
      filterItems,
    });
  }

  async onStop (code) {
    try {
      await API.annualPlans.stop(code);
      Alert.success(localizeMessage({ id: 'annualPlans.annualPlan-successfully-stopped' }));
    } catch (error) {
      console.error(error);

      Alert.error(`${localizeMessage({ id: 'annualPlans.annualPlan-failed-stop' })} : ${code}`);
    }
  }

  onDelete (id) {
    SweetAlert({
      title: localizeMessage({ id: 'annualPlans.annualPlan-delete' }),
      text: localizeMessage({ id: 'annualPlans.annualPlan-will-be-deleted' }),
      buttons: {
        confirm: localizeMessage({ id: 'yes' }),
        cancel: localizeMessage({ id: 'cancel' }),
      },
    })
      .then(async isConfirm => {
        if (isConfirm) {
          try {
            await API.annualPlans.delete(id);

            Alert.success(localizeMessage({ id: 'annualPlans.annualPlan-successfully-deleted' }));

            this.refreshList();
            this.refreshFilterItems();
          } catch (error) {
            console.error(error);

            Alert.error(`${localizeMessage({ id: 'annualPlans.annualPlan-failed-delete' })} : ${id}`);
          }
        }
      });
  }

  async onClone (id) {
    try {
      await API.annualPlans.copy(id);

      Alert.success(localizeMessage({ id: 'annualPlans.annualPlan-successfully-copied' }));

      this.refreshList();
      this.refreshFilterItems();
    } catch (error) {
      console.error(error);

      Alert.error(`${localizeMessage({ id: 'annualPlans.annualPlan-failed-clone' })}: ${id}`);
    }
  }

  async onExport (annualPlan) {
    try {
      const mediaPlan = await API.annualPlans.get(annualPlan.code);
      const response = await API.annualPlans.json(annualPlan.id);
      let blob = await response.blob();
      const textFromBlob = await blob.text();
      const parsedJson = JSON.parse(textFromBlob);

      const regions = mediaPlan.mediaplans.map(({ channel }) => channel?.label || 'Федеральные каналы');
      parsedJson.mediaplans = parsedJson.mediaplans.map((plan, index) => ({ region: regions[index], ...plan }));

      blob = new Blob([JSON.stringify(parsedJson)], { type: 'plain/text' });

      const contentDisposition = response.headers.get('content-disposition');
      const filenameData = contentDisposition
        ? contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
        : null;
      const filename = filenameData
        ? filenameData[1].replace(/['"]+/g, '')
        : `${annualPlan.name}_${annualPlan.code}.txt`;

      saveAs(blob, filename);

      Alert.success(localizeMessage({ id: 'annualPlans.annualPlan-successfully-export' }));
    } catch (error) {
      console.error(error);

      Alert.error(
        `${localizeMessage({ id: 'annualPlans.annualPlan-failed-export' })}: "${annualPlan.name}" [${annualPlan.code}]`,
      );
    }
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app',
      },
      {
        title: <LocalizedMessage id='annualPlans' />,
        link: '/app/annualPlans',
      },
    ];
  }

  render () {
    const {
      checkRoles,
      onUpdateSort, onUpdatePage, filters, onChangeFilterItems,
    } = this.props;

    const {
      filterItems,
      dataListTotal,
      dataListActive,
      dataList,
      isLoading,
    } = this.state;

    const jsonAllowed = checkRoles('ADMIN') || checkRoles('SUPER_ADMIN');
    const tableHead = [
      {
        label: localizeMessage({ id: 'name' }),
        sort: 'name',
      },
      {
        label: localizeMessage({ id: 'advertiser' }),
        sort: 'advertiser_keycloak_id',
      },
      {
        label: localizeMessage({ id: 'author' }),
        sort: 'author_id',
      },
      {
        label: localizeMessage({ id: 'modified-at' }),
        sort: 'last_modified',
      },
      {
        label: localizeMessage({ id: 'status' }),
        sort: 'status',
      },
      {
        empty: true,
        props: {
          className: 'controls',
        },
      },
    ];

    if (jsonAllowed) {
      tableHead.push({
        empty: true,
        props: {
          className: 'json-download',
        },
      });
    }

    return (
      <>
        <LocalizedMessage
          id={['site.title.annualPlans', 'site.title.annualPlans.add']}
        >
          {(localizedAnnualPlansMessage, localizedAddAnnualPlanMessage) => (
            <>
              <Helmet
                title={localizedAnnualPlansMessage}
              />
              <Breadcrumbs
                title={localizedAnnualPlansMessage}
                data={this.getBreadcrumbs()}
                className='col-lg-4'
              >
                <div className='col-lg-8'>
                  <div className='title-action'>
                    {
                      checkRoles('SUPER_ADMIN,ADMIN,PLANNER,BUYER') && (
                        <Link
                          className='btn btn-success'
                          to='/app/annualPlans/create/MULTIMEDIA'
                          data-test='add-new-mediaplan'
                        >
                          <i className='fa fa-plus fa-lg' />
                          <span>{localizedAddAnnualPlanMessage}</span>
                        </Link>
                      )
                    }
                  </div>
                </div>
              </Breadcrumbs>
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='row'>
                    <div className='col-sm-12 m-b-sm d-flex'>
                      <div className='flex-grow-1 m-r'>
                        <FilterList
                          placeholder={<LocalizedMessage id='annualPlans.filter.placeholder' />}
                          options={filterItems}
                          onChange={onChangeFilterItems}
                          value={filters.items}
                        />
                      </div>
                    </div>
                  </div>

                  {!isLoading && (
                    <div className='row'>
                      <div className='col-sm-2 m-b-sm d-flex'>
                        &nbsp;&nbsp;
                        <LocalizedMessage id='total' />
:
                        {dataListTotal}
                      </div>
                      <div className='col-sm-2 m-b-sm d-flex'>
                        <LocalizedMessage id='active' />
:
                        {dataListActive}
                      </div>
                      <div className='col-sm-8 m-b-sm' />
                    </div>
                  )}

                  <div className={cx(
                    'table-responsive',
                    classes.AnnualPlans,
                  )}
                  >
                    <TableList
                      currentPage={filters.currentPage}
                      currentSort={filters.currentSort}
                      totalItems={dataListTotal}
                      itemsCountPerPage={filters.max}
                      onUpdateSort={onUpdateSort}
                      onUpdatePage={onUpdatePage}
                      isLoading={isLoading}
                      head={tableHead}
                    >
                      {
                        dataList.map(annualPlan => (
                          <tr key={annualPlan.code}>
                            <td className='name-col'>
                              {
                                annualPlan.status.name === 'COMPLETE'
                                  ? (
                                    <Link
                                      to={`/app/annualPlans/${annualPlan.code}`}
                                    >
                                      {annualPlan.name}
                                    </Link>
                                  )
                                  : <span>{annualPlan.name}</span>
                              }
                            </td>
                            <td>
                              {annualPlan.advertiser.name}
                            </td>
                            <td>
                              {annualPlan.author.name}
                            </td>
                            <td>
                              {moment(annualPlan.lastModified, 'YYYY-MM-DD@HH:mm').format('DD/MM/YY HH:mm')}
                            </td>
                            <td>
                              {annualPlan.status.nameTranslation}
                            </td>
                            <td className='text-right text-nowrap'>
                              <button
                                className='btn btn-info btn-xs'
                                onClick={() => this.onClone(annualPlan.id)}
                              >
                                {' '}
                                <span><LocalizedMessage id='copy' /></span>
                                {' '}
                              </button>
                              {' '}
                              <Link
                                className='btn btn-primary btn-xs'
                                to={`/app/annualPlans/${annualPlan.code}/edit`}
                              >
                                {' '}
                                <span><LocalizedMessage id='edit' /></span>
                                {' '}
                              </Link>
                              {' '}
                              <button
                                className='btn btn-warning btn-xs'
                                onClick={() => this.onStop(annualPlan.code)}
                              >
                                {' '}
                                <span><LocalizedMessage id='stop' /></span>
                                {' '}
                              </button>
                              {' '}
                              <button
                                className='btn btn-danger btn-xs'
                                onClick={() => this.onDelete(annualPlan.id)}
                              >
                                {' '}
                                <span><LocalizedMessage id='delete' /></span>
                                {' '}
                              </button>
                            </td>
                            {jsonAllowed && (
                            <td>
                              {annualPlan.hasJsonForOptimizer && (
                              <Tooltip
                                placement='top'
                                overlay={(
                                  <LocalizedMessage id='annualPlans.json' />
        )}
                              >
                                <div
                                  className='fa fa-file-code-o _cursor--pointer'
                                  onClick={() => this.onExport(annualPlan)}
                                />
                              </Tooltip>
                              )}
                            </td>
                            )}
                          </tr>
                        ))
                      }
                    </TableList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(AnnualPlans, '-last_modified');
